import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Bio from '@nehalist/gatsby-theme-nehalem/src/components/bio/index';
import { StickySidebarContent } from '@nehalist/gatsby-theme-nehalem/src/components/sidebar-content/style';
import Theme from '../../styles/theme';

const blogRoot = 'https://www.wimpyprogrammer.com';
const npmRoot = 'https://www.npmjs.com/package';
const sideProjects = [
	['RegEx to Strings', `${blogRoot}/regex-to-strings/`],
	['Strings to RegEx', `${blogRoot}/strings-to-regex/`],
	[
		'Google Classroom Bookmarklets',
		`${blogRoot}/google-classroom-bookmarklets/`,
	],
	['React Responsive Breakpoints', `${npmRoot}/react-responsive-breakpoints`],
	['React Component Update', `${npmRoot}/react-component-update`],
	['Conditional Expression Parser', `${blogRoot}/conditional-parser/`],
];

const ProjectLink = styled.a`
	color: ${Theme.layout.linkColor};
`;

const ProjectList: FunctionComponent = () => (
	<p>
		My side projects:
		<ul>
			{sideProjects.map(([name, url]) => (
				<li key={name}>
					<ProjectLink href={url}>{name}</ProjectLink>
				</li>
			))}
		</ul>
	</p>
);

/**
 * Placeholder for the front page sidebar content.
 */
const SidebarContent: FunctionComponent = () => (
	<StickySidebarContent>
		<Bio
			textAlign="justify"
			avatarStyle={{ float: `left`, margin: `0 20px 5px 0` }}
		/>
		<ProjectList />
	</StickySidebarContent>
);

export default SidebarContent;
