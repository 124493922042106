import React, { FunctionComponent } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { AvatarProps } from '@nehalist/gatsby-theme-nehalem/src/components/avatar/index';
import { StyledAvatar } from '@nehalist/gatsby-theme-nehalem/src/components/avatar/style';

const Avatar: FunctionComponent<AvatarProps> = ({ alt, style }) => {
	const logo = useStaticQuery(graphql`
		query {
			file(sourceInstanceName: { eq: "themeAssets" }, name: { eq: "avatar" }) {
				childImageSharp {
					gatsbyImageData(width: 55, height: 55)
				}
			}
		}
	`);

	return (
		<StyledAvatar
			image={logo.file.childImageSharp.gatsbyImageData}
			alt={alt}
			style={style}
		/>
	);
};

export default Avatar;
